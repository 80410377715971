.test-main-container {
  width: 100%;
  padding-top: 10px;
  padding-right: 20px;

  display: flex;
  align-items: center;
}

.arrow-left {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  cursor: pointer;
}

.arrow-right {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}

.header-slider {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.all-items-container {
  height: 70px;
  display: flex;

  transition: translate;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.div-msg-style {
  background-color: #ffe358;
  color: #000000;
  margin: 5px;
}

.p-msg-style {
  padding: 5px 10px 5px 10px;
  margin: 0;
}

.slick-dots {
  display: flex;
  justify-content: center;

  margin: 0;
  padding: 0 0 10px;
  list-style: none;

  width: 50%;

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots button {
  width: 20px;
  height: 20px;
  padding: 0;

  background: #fff;
  border-radius: 50%;
  border: 0;
  opacity: 0.5;
  cursor: pointer;

  font-size: 0;
  color: transparent;
}

.slick-dots button:focus {
  outline: 0;
}

.slick-dots .slick-active button {
  opacity: 1;
}

.item {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.item-1 {
  background-color: rgb(255, 227, 88);
  color: black;
}

.item-2 {
  background-color: yellowgreen;
  color: black;
}

.item-3 {
  background-color: pink;
  color: black;
}
