.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}

.title {
  font-size: 22px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 30px;
  color: #00274e;
  transform: scale(1, 1.1);
  letter-spacing: 0.1rem;
  text-align: left;
}

@media screen and (max-width: 1349px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
}

@media screen and (max-width: 1099px) {
  .container {
    height: calc(100vh - 124px);
  }
  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 959px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .title {
    font-size: 18px;
    line-height: 25px;
  }
}

@media screen and (max-width: 499px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;

    margin-top: 200px;
    align-items: flex-start;
  }
  .title {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 349px) {
  .container {
    margin-left: 5px;
    margin-right: 5px;
  }
  .title {
    font-size: 14px;
    line-height: 19px;
  }
}
